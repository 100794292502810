import React, { useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import { ThemeProvider } from 'styled-components';
import withWidth from '@material-ui/core/withWidth';
import { Grid } from 'react-styled-flexboxgrid';
import { useStaticQuery, graphql } from 'gatsby';

import STRIPE_PRODUCTS from '../../../SITE_CONSTANTS/STRIPE_PRODUCTS';
import { compose, isMobileWidth, Spacer } from 'utils';
import { useCartContext } from '../../context/cart';
import { Animated } from 'components';
import content from './products/content';
import Book from './products/Book';

const theme = {
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 1, // rem
    outerMargin: 0, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 40, // rem
      md: 50, // rem
      lg: 60, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 45, // em
      md: 53, // em
      lg: 63, // em
    },
  },
};

const { title, cartText, books } = content;

const Shop = ({ width }) => {
  const {
    allFile: { nodes },
  } = useStaticQuery(graphql`
    query {
      allFile(filter: { absolutePath: { regex: "/images/covers/" } }) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
          relativePath
        }
      }
    }
  `);

  const [, dispatch] = useCartContext();

  const imageObj = useMemo(
    () =>
      nodes.reduce((acc, cur) => {
        acc[cur.relativePath] = cur.childImageSharp.fluid;
        return acc;
      }, {}),
    [nodes]
  );

  const pageBooks = books.map(book => {
    book.image = imageObj[book.imagePath];
    return book;
  });

  const handleAddItem = bookId => {
    dispatch({
      type: 'ADD_TO_CART',
      payload: { ...STRIPE_PRODUCTS[bookId], quantity: 1 },
    });
  };

  const isMobile = isMobileWidth(width);

  return (
    <>
      <Fade in timeout={1000}>
        <Typography variant='h1' color='textPrimary'>
          <Animated>{title}</Animated>
        </Typography>
      </Fade>

      <Spacer mobile={30} desktop={35} isMobile={isMobile} />
      <ThemeProvider theme={theme}>
        <Grid>
          {pageBooks.map(book => {
            return (
              <div key={book.imagePath}>
                <Book
                  bookTitle={book.bookTitle}
                  author={book.author}
                  bookSummary={book.bookSummary}
                  image={book.image}
                  cartText={cartText}
                  bookKey={book.bookKey}
                  handleAddItem={() => handleAddItem(book.bookKey)}
                  price={STRIPE_PRODUCTS[book.bookKey].price}
                />
                <Spacer mobile={90} desktop={45} isMobile={isMobile} />
              </div>
            );
          })}
        </Grid>
      </ThemeProvider>
    </>
  );
};

export default compose(withWidth())(Shop);
