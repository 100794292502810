import React, { useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import { ThemeProvider } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import withWidth from '@material-ui/core/withWidth';
import { Grid } from 'react-styled-flexboxgrid';

import { compose, isMobileWidth, Spacer } from 'utils';
import AuthorRow from './AuthorRow';
import { Animated } from 'components';
import content from './content';

const theme = {
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 1, // rem
    outerMargin: 0, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 40, // rem
      md: 50, // rem
      lg: 60, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 45, // em
      md: 53, // em
      lg: 63, // em
    },
  },
};

const { title, authors } = content;

const Authors = ({ width }) => {
  const {
    allFile: { nodes },
  } = useStaticQuery(graphql`
    query {
      allFile(filter: { absolutePath: { regex: "/images/authors/" } }) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
          relativePath
        }
      }
    }
  `);

  const imageObj = useMemo(
    () =>
      nodes.reduce((acc, cur) => {
        acc[cur.relativePath] = cur.childImageSharp.fluid;
        return acc;
      }, {}),
    [nodes]
  );

  const pageAuthors = authors.map(author => {
    author.image = imageObj[author.imagePath];
    return author;
  });

  const isMobile = isMobileWidth(width);

  return (
    <>
      <Fade in timeout={1000}>
        <Typography variant='h1' color='textPrimary'>
          <Animated>{title}</Animated>
          <Spacer mobile={20} desktop={35} isMobile={isMobile} />
        </Typography>
      </Fade>

      <Spacer mobile={10} desktop={0} isMobile={isMobile} />

      <ThemeProvider theme={theme}>
        <Grid>
          {pageAuthors.map(author => (
            <div key={author.imagePath}>
              <AuthorRow author={author} />
              <Spacer mobile={90} desktop={45} isMobile={isMobile} />
            </div>
          ))}
        </Grid>
      </ThemeProvider>
    </>
  );
};

export default compose(withWidth())(Authors);
