const content = {
  title: 'Shop.',
  cartText: 'ADD TO CART',
  books: [
    {
      bookKey: 'FED_UP',
      bookTitle: 'Fed Up: An Illustrated Guide to Food Freedom',
      author: 'by Katie Barbaro',
      bookSummary: `Fed Up is an illustrated guide to dismantling diet culture told through the raw, honest, diary-divulging perspective of Katie Barbaro, who draws from her experience of compulsive over-exercising, calorie-counting, bulimia, and people pleasing. For people who have grown up in the Western world, there’s a 99.9% chance we’ve been indoctrinated by diet culture and fat phobia, which has systematically distorted the ways in which people have related to their bodies, food, and “health.” Many of us aren’t even aware we've grown up in this cult! Through a series of autobiographical cartoons and stories, we’ll be taken on a journey to un-brainwash ourselves from the cult of diet culture by playfully questioning and letting go of what we’ve always believed to be true.`,
      imagePath: 'covers/fedUp.jpg',
    },
    {
      bookKey: 'WISP',
      bookTitle: 'Wisp',
      author: 'by Annika Rodrigues',
      bookSummary: `Wisp tells the tale of a man who lives an orderly and predictable life. That is, until one spring day when something unexpected blows into town. Author and illustrator Annika Rodrigues offers Wisp as a story of hope and fresh air in a turbulent time. As a new mother on the eve of the COVID pandemic, she seeks to capture the feelings of loss, indignance, and gradual acceptance of dramatic change to everyday life. Written as a short poem, each page is filled with illustrations interwoven with photos of carefully-arranged leaves and blossoms gathered at the family farm where the author quarantined that spring. Estimated Ship Date for Wisp is October 22, 2021.`,
      imagePath: 'covers/wisp.jpg',
    },
  ],
};

export default content;
