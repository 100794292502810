// import { FaGithub } from 'react-icons/fa';

const content = {
  title: 'About.',
  subtitleP1:
    'Orchard Press is a publishing company whose goal is to work with upcoming bestselling authors and support them in telling their story. Orchard Press believes a publisher should help authors create their best possible works, not limit what they can do. Since each book is written differently and each author has their own process, each project requires a catered approach. A good publisher must act as an editor, a designer, a publicist, a printer, a salesman, a bookkeeper, a cheerleader and a friend.',
  subtitleP2:
    'We seek out titles and authors that excite us to the core. Books-to-be that would make us sit on the floor of the bookstore until closing, pawing through them, until finally bringing them in our arms up to the register. Do you write that kind of book? If so, let us know. carole@orchardpresspublishing.com.',
  // links: [
  //   {
  //     to: 'https://github.com/chronisp',
  //     text: 'chronisp',
  //     Icon: FaGithub,
  //     newTab: true,
  //   },
  //   {
  //     to: 'https://github.com/chronisp/gatsby-starter',
  //     text: 'Gatsby Starter',
  //     Icon: FaGithub,
  //     newTab: true,
  //   },
  // ],
};

export default content;
