import React from 'react';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import Img from 'gatsby-image';
import withWidth from '@material-ui/core/withWidth';
import { Col, Row } from 'react-styled-flexboxgrid';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Button from '@material-ui/core/Button';
import { Animated } from 'components';

import { compose, isMobileWidth, Spacer } from 'utils';
import PriceObject from '../../../classes/PriceObject';

const COLUMNS = {
  image: {
    lg: 3,
    md: 3,
    sm: 12,
    xs: 12,
  },
  content: {
    lg: 9,
    md: 9,
    sm: 12,
    xs: 12,
  },
};

const StyledImg = styled(Img)`
  display: block;
  border-radius: 25px;
  max-width: 350px;
  width: 100%;
  margin: auto;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;

const Book = ({
  width,
  bookTitle,
  // author,
  bookSummary,
  image,
  cartText,
  price,
  handleAddItem,
}) => {
  const isMobile = isMobileWidth(width);

  return (
    <Row>
      <Col {...COLUMNS.image}>
        <Spacer mobile={0} desktop={6} isMobile={isMobile} />
        <StyledImg fluid={image} />
      </Col>
      <Col {...COLUMNS.content} style={{ paddingRight: 0 }}>
        <ContentContainer>
          <Spacer mobile={20} desktop={0} isMobile={isMobile} />

          <Typography
            variant='h5'
            color='textPrimary'
            style={{ fontWeight: 'bold', fontSize: '1.63rem' }}>
            <Animated>{bookTitle}</Animated>
          </Typography>

          <Spacer mobile={10} desktop={5} isMobile={isMobile} />

          <Typography
            variant='subtitle1'
            color='textPrimary'
            style={{ fontSize: '1rem' }}>
            {bookSummary}
          </Typography>
        </ContentContainer>

        <Spacer mobile={20} desktop={15} isMobile={isMobile} />

        <Button
          variant='contained'
          color='primary'
          endIcon={<ShoppingCartIcon />}
          fullWidth={isMobile}
          size='large'
          onClick={handleAddItem}>
          {PriceObject.humanPriceFormat(price)} - {cartText}
        </Button>
      </Col>
    </Row>
  );
};

export default compose(withWidth())(Book);
