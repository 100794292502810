const STRIPE_PRODUCTS = {
  FED_UP: {
    productId: '12345', // fake id, unique to app
    type: 'Hardcover Book',
    name: 'Fed Up',
    price: 2100,
    priceId:
      process.env.NODE_ENV === 'development'
        ? 'price_1JeUoKDDni7X5qGqQ0jt5Af2'
        : 'price_1JeUlTDDni7X5qGq04NBmI2X',
    author: 'Katie Barbaro',
    imageRelativePath: 'covers/fedUp.jpg',
  },
  WISP: {
    productId: '54321',
    type: 'Hardcover Book',
    name: 'Wisp',
    price: 2000,
    priceId:
      process.env.NODE_ENV === 'development'
        ? 'price_1IH0P6DDni7X5qGq07GOOpYM'
        : 'price_1IH000DDni7X5qGqfeXxn6vU',
    author: 'Annika Rodrigues',
    imageRelativePath: 'covers/wisp.jpg',
  },
};

export default STRIPE_PRODUCTS;
