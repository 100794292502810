import React from 'react';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import withWidth from '@material-ui/core/withWidth';

import { Animated } from 'components';
import { compose, isMobileWidth, Spacer } from 'utils';
import content from './content';

const { title, subtitleP1, subtitleP2 } = content;

const About = ({width}) => {
  const isMobile = isMobileWidth(width)
  return <>
    <Fade in timeout={1000}>
      <Typography variant='h1' color='textPrimary'>
        <Animated>{title}</Animated>
      </Typography>
    </Fade>

    <Spacer mobile={5} desktop={0} isMobile={isMobile}/>

    <Typography variant='h6' color='textPrimary'>
      {subtitleP1}
    </Typography>

    <Spacer mobile={15} desktop={15} isMobile={isMobile}/>

    <Typography variant='h6' color='textPrimary'>
      {subtitleP2}
    </Typography>

    {/* <LinkList links={links} /> */}
  </>
};

export default compose(
  withWidth(),
)(About);

