const content = {
  title: 'Authors.',
  authors: [
    //  katie
    {
      authorName: 'Katie Barbaro',
      authorBio1: `Katie Barbaro is a comedian, cartoonist, and life coach passionate about helping people live the lives of their dreams through laughter AND shedding the parts of their identities that do not serve them. Originally from Los Angeles and most recently living in NYC, Katie has spent the past year and a half creating a life driven by her values by traveling around the world, performing, creating art, and building community.`,
      authorBio2: `Katie began her comedy journey in 2004, performing improv with ComedySportz in Los Angeles and eventually NYC. She has also performed standup in Los Angeles, New York City, Iceland, Bali, The Netherlands, London, and Paris. She is currently writing and drawing a cartoon-based book based on her journey of recovering from an eating disorder: "Fed Up: Reclaim Your Life Through Your Relationship with Food."`,
      imagePath: 'authors/KatieBarbaro.jpeg',
    },

    // annika
    {
      authorName: 'Annika Rodrigues',
      authorBio1:
        'Annika Rodrigues is the author and illustrator of Wisp. A product designer by day, artist by night, and mother at all times, her story-telling is often inspired by grand childhood adventures on her family’s farm in Wenatchee, Washington. Annika earned her BA in Design and Print Production from Western Washington University, where she learned to hand craft books of her earliest stories.',
      authorBio2:
        'Today Annika lives with her husband and son in North Seattle. She is an avid runner, chicken enthusiast, and collector of house plants (which typically don’t survive despite her agricultural roots).',
      imagePath: 'authors/AnnikaRodrigues.jpg',
    },
  ],
};

export default content;
