import React from 'react';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import Img from 'gatsby-image';
import withWidth from '@material-ui/core/withWidth';
import { Col, Row } from 'react-styled-flexboxgrid';
import { Animated } from 'components';

import { compose, isMobileWidth, Spacer } from 'utils';

const COLUMNS = {
  image: {
    lg: 4,
    md: 4,
    sm: 12,
    xs: 12,
  },
  content: {
    lg: 8,
    md: 8,
    sm: 12,
    xs: 12,
  },
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const StyledImg = styled(Img)`
  display: block;
  border-radius: 25px;
  max-width: 350px;
  width: 100%;
  margin: auto;
`;

const AuthorRow = ({ author, width }) => {
  const isMobile = isMobileWidth(width);
  return (
    <Row>
      <Col {...COLUMNS.image}>
        <Spacer mobile={0} desktop={6} isMobile={isMobile} />

        <StyledImg fluid={author.image} />

        <Spacer mobile={17} desktop={0} isMobile={isMobile} />
      </Col>

      <Col {...COLUMNS.content}>
        <ContentContainer>
          <Typography
            variant='h4'
            color='textPrimary'
            style={{ fontWeight: 'bold', fontSize: '1.63rem' }}>
            <Animated>{author.authorName}</Animated>
          </Typography>
          <Spacer mobile={10} desktop={8} isMobile={isMobile} />
          <Typography
            variant='subtitle1'
            color='textPrimary'
            style={{ fontSize: '1rem' }}>
            {author.authorBio1}
          </Typography>
          <Spacer mobile={15} desktop={15} isMobile={isMobile} />
          <Typography
            variant='subtitle1'
            color='textPrimary'
            style={{ fontSize: '1rem' }}>
            {author.authorBio2}
          </Typography>
        </ContentContainer>
      </Col>
    </Row>
  );
};

export default compose(withWidth())(AuthorRow);
